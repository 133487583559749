<template>
  <div class="container-fluid text-left">
    <div class="card nw-card">
      <div class="card-header nw-card-header">
        <h4>Log Events - {{ nc.ctx.patientName }}</h4>
      </div>
      <div class="card-body" >
        <div class="row mt-2">
          <div class="col-6 text-left">
            <button class="btn btn-outline-secondary" @click="back()"><i class="fa fa-chevron-left"></i></button>
          </div>
          <div class="col-6 text-right">
            {{ skip }}
            <button class="btn btn-outline-secondary" @click="next()"><i class="fa fa-chevron-right"></i></button>
          </div>
        </div>

        <table class="table table-hover table-sm mt-2">
          <thead><tr><th>Time</th><th>Description</th><th>Type</th><th>Error</th><th>Answer</th></tr></thead>
          <tbody style="cursor:pointer">
            <tr v-for="item in arr">
              <td>{{ item.eventTime}}</td>
              <td>{{ item.description}}</td>
              <td>{{ item.eventType}}</td>
              <td>{{ item.error }}</td>
              <td><span v-show="item.answer >= 0">{{ item.answer}}</span></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
import NetClient from '@/util/netclient.js'

export default {
  mounted () {
      this.search()
  },
  data () {
    return {
      nc: NetClient,
      arr: [],
      skip: 0,
      limit: 10
    }
  },
  methods: {
    search () {
        NetClient.doRequest('rest/patient', { action: 'getLogEvents', skip: this.skip, limit: this.limit}).then(msg => {
          if(typeof msg.data != 'undefied') this.arr = msg.data
        })
    },
    back () {
      this.skip  = this.skip - this.limit
      if(this.skip < 0) this.skip = 0
      this.search()
    },
    next () {
      if(this.arr.length < this.limit) return
      this.skip = this.skip + this.limit
      this.search()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>


</style>
